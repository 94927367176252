@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Gilroy;
  src: url('../public/fonts/Gilroy-Font/Gilroy-Medium.woff2');
}

@font-face {
  font-family: GilroyBold;
  src: url('../public/fonts/Gilroy-Font/Gilroy-Bold.woff2');
}
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
body.dark {
  background: #202226;; /* Dark mode background */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff; /* Light mode background */
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  body {
    background: #17181A; /* Dark mode background */
  }
}
@media (prefers-color-scheme: dark) {
  body {
    background: theme('colors.black-shade2-background'); /* Dark mode background */
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  background: transparent;
}


input:focus {
  outline: none;
}

.dark-calendar div {
  background-color: #242322 !important;
}

.dark-calendar div table tbody tr td:hover {
  color: #242322 !important;
}

.dark-calendar div table tfoot tr td:hover {
  color: #242322 !important;
}

.dark-calendar .rdtTime:hover {
  color: white !important;
}

.dark-calendar .rdtTime table tbody tr td:hover {
  color: white !important;
}

.dark-calendar .rdtBtn:hover {
  color: #242322 !important;
}

.filled {
  background: linear-gradient(270.01deg, #C89C34 8.72%, #FFBB1C 78.42%);
}

.transition-sidebar-close {
  max-height: 0px;
  transition: max-height 0.3s ease-in-out 0s;
}


.transition-sidebar-open {
  max-height: 144px;
  transition: max-height 0.3s ease-in-out 0s;
}

.logo-div {
  margin-bottom: 20px;
}

.item-text-span {
  font-family: 'Gilroy';
  display: inline-block;
  vertical-align: top;
  padding: 5px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.02em;
  vertical-align: top;
}

.item-tick {
  display: inline-block;
  vertical-align: top;
  padding-top: 10px;
  margin-left: 10px;
}

.title-div {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: 0.04em;
}

.subtitle-span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: 0.04em;
  color: #A69F9F;
  padding-right: 10px;
}

.status-success {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #84DF3C;
}

.status-pending {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #E56060;
}

.item-wrap {
  position: absolute;
  top: 70px;
  border: 3px solid rgba(166, 159, 159, 0.2);
  box-shadow: 0px 0px 12px #a7a7a7;
  border-radius: 8px;
  padding: 20px;
  min-width: 300px;
}
@media screen and (max-width: 880px) {
  .item-wrap {
    top: 50px;
    left: -150px;
  }
  
}
.menu-items {
  margin: 0px 15px;
  cursor: pointer;
}

.menu-title {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: 0.04em;
  color: #464754;
  margin-bottom: 20px;
}

.menu-items-div {
  display: flex;
  background: #F5F1EB;
  cursor: pointer;
  box-shadow: 0px 0px 2px #dcdcdc;
  padding: 8px;
}

.menu-item-left {
  display: flex;
  padding: 0px;
  margin-right: 10px;
}

.item-icon {
  display: inline-block;
}


.item-text {
  font-family: 'Gilroy';
  display: inline-block;
  vertical-align: top;
  padding: 5px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;

}


@media (min-width: 481px) and (max-width: 767px) {
  .item-text {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .item-text {
    display: none;
  }
}
