.filterBorder {
    border-width: 0px 0.5px 0.5px 0.5px;
    border-style: solid;
}

.filter::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.filter {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}