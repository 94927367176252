.topBar{
    position: fixed;
    top: 0;
    z-index: 80;
}

.sideBar{
    position: fixed;
    top: 100px;
    z-index: 50;
    min-height: calc(100% - 100px);
}

@media screen and (max-width:760px) {
    .topBar{
        position: sticky;
    }
}